import React from 'react'
import Layout from '../layouts'
import { Link, graphql } from 'gatsby'
import HeroSlider from '../components/hero/index.js'
import Contact from '../components/contact/contact.js'
import Partners from '../components/partners/index.js'
import store from '../images/store.jpg'
import truck from '../images/truck.jpg'
import psa from '../images/psa.jpg'
import mediakit from '../images/media-kit.jpg'
import mobilekit from '../images/mobile-kit.jpg'
import trimaxmediakit from '../../static/assets/Trimex Outdoor Media Kit 2024 Timar.pdf'
import trimaxmobilekit from '../../static/assets/trimex-outdoor-mobile-tours.pdf'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div id="hero">
          <HeroSlider />
        </div>
        <div id="index">
          <section id="title">
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <h1 className="heading-title">
                    In-Store Advertising, Lunch Truck Advertising, Bill Posters,
                    Food Carts, Ice Cream Truck and Paleta Cart Advertising with
                    100% POP Provided!
                  </h1>
                </div>
              </div>
            </div>
          </section>
          <section id="about-us">
            <div className="container">
              <div className="row">
                <div className="col-12 service-title-content">
                  <div className="service-content whatwedo text-center">
                    <div className="overlay">
                      <h2 className="section-title">
                        <strong>ABOUT US</strong>
                        <br />
                      </h2>
                      <h3 className="section-subtitle mt-2">
                        Trimex Outdoor is a Women-Owned Business that has over
                        40 years experience handling Out-of-Home and custom PSA
                        campaigns for clients across nearly every major
                        industry. Since 1974, we are proud to be #1 in the U.S.
                        in Lunch Truck Advertising, a leader in Local
                        Place-Based Advertising, and a trusted partner for
                        important State and Federal PSA Campaigns.
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4 h-100 d-inline-block">
                  <div className="service-content text-center">
                    <img src={store} className="img-fluid" alt="Store" />
                    <div className="overlay">
                      <h3 className="service-title">
                        <strong>In-Store Advertising Network</strong>
                        <br />
                      </h3>
                      <p className="service-p mt-2">
                        We offer custom showings targeting all types of
                        industries and demographics, including Hispanic, Asian,
                        AA, Millennials, Commuters, Families, Expectant Mothers,
                        Construction Workers, Public Servants and more.…
                      </p>
                      <div className="d-flex justify-content-center mt-4">
                        <Link
                          to="/storefront-advertising"
                          className="link-btn-green"
                        >
                          READ MORE
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4 h-100 d-inline-block">
                  <div className="service-content text-center">
                    <img src={truck} className="img-fluid" alt="Truck" />
                    <div className="overlay">
                      <h3 className="service-title">
                        <strong>Lunch Truck Advertising Network</strong>
                        <br />
                      </h3>
                      <p className="service-p mt-2">
                        Trimex Outdoor's Lunch Trunk Network operates Nationally
                        in 42 U.S. Markets. Our network includes over 2,000
                        Lunch Trucks coast to coast and is a great option for
                        targeting Blue Collar, Construction, Factory and Farm
                        Workers, and also daily commuters.
                      </p>
                      <div className="d-flex justify-content-center mt-4">
                        <Link
                          to="/lunch-truck-advertising"
                          className="link-btn-green"
                        >
                          READ MORE
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4 h-100 d-inline-block">
                  <div className="service-content text-center">
                    <img src={psa} className="img-fluid" alt="PSA" />
                    <div className="overlay">
                      <h3 className="service-title">
                        <strong>Customized PSA Campaigns</strong>
                        <br />
                      </h3>
                      <p className="service-p mt-2">
                        Trimex Outdoor is a leader in State and Federal PSA
                        Campaigns customized to target specific audiences
                        through OOH placements at public, private and government
                        properties across the U.S.
                      </p>
                      <div className="d-flex justify-content-center mt-4">
                        <Link
                          to="/customized-psa-campaigns"
                          className="link-btn-green"
                        >
                          READ MORE
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="media-kit">
            <h2 className="section-title text-center mb-5">Media Kit</h2>
            <div className="container">
              <img src={mediakit} className="img-fluid" alt="mediakit" />
              <div className="d-flex justify-content-center mt-4">
                <a
                  class="link-btn-green"
                  href={trimaxmediakit}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  Download
                </a>
              </div>
            </div>
            <div className="container mt-5">
              <img src={mobilekit} className="img-fluid" alt="mediakit" />
              <div className="d-flex justify-content-center mt-4">
                <a
                  class="link-btn-green"
                  href={trimaxmobilekit}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  Download
                </a>
              </div>
            </div>
          </section>
          ;
          {/*<section id="lunch">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<div className="lunch-content p-5">
										<h2 className="section-title mb-4 red">
											Lunch Truck Advertising
										</h2>
										<p className="section-p">
											Blue Collar Demo, including those who work in
											construction, manufacturing, on farms and other industrial
											places of employment. The typical lunch truck makes 20
											stops and serves 500 customers every working day.
										</p>
										<p className="section-p mb-4">
											Advertising impressions are developed from traffic during
											drive time between stops with a combined average daily
											effective circulation of (D.E.C.) 9,000 per truck.
										</p>
										<div className="d-flex mt-4">
											<Link
												to="/lunch-truck-advertising"
												className="link-btn"
												title="learn more about lunch truck advertising"
											>
												Read More
											</Link>
										</div>
									</div>
								</div>
								<div className="col-md-6" />
							</div>
						</div>
					</section>*/}
          {/*<section id="storefront">
						<div className="container">
							<div className="row">
								<div className="col text-center">
									<div className="store-content">
										<h2 className="section-title mb-4">
											Storefront Urban and Rural Advertising
										</h2>
										<h3 className="section-subtitle mb-4">
											Trimex Outdoor offers&nbsp;Urban – Rural Neighborhood
											Advertising&nbsp;with the biggest impact at the lowest
											rates!
											<br />
										</h3>
										<p className="section-p">
											We offer custom showings targeting all types of industries
											and demographics, including Hispanic, Asian, AA,
											Millennials, General Market, Hipsters, Teens, Expecting
											Mothers, Construction Workers, Public Servants and more.…
										</p>
										<div className="d-flex justify-content-center mt-4">
											<Link
												to="/storefront-urban-rural-advertising"
												className="link-btn"
												title="learn more about storefront urban rural advertising"
											>
												Read More
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>*/}
          <section id="clients">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="clients-content">
                    <h2 className="section-title text-center mb-5">
                      Our Clients
                    </h2>
                    <div className="swiper-content">
                      <Partners />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Contact />
        </div>
      </Layout>
    )
  }
}
export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        googleverification
        meta {
          description
        }
      }
    }
  }
`
