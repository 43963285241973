import React, { Component } from "react"
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/css/swiper.css"
import miller from "../../images/Miller_Coors.png"
import beam from "../../images/beam-suntory-logo2.png"
import jarritos from "../../images/jarritos.png"
import pepsico from "../../images/logo_pepsico.png"
import modelo from "../../images/ModeloSAB11.jpg"
import busch from "../../images/Anheuser_Busch.png"
import moctezuma from "../../images/Cuauhtemoc-Moctezuma-Holding-–-Tecate-Beer.png"
import dickies from "../../images/dickies.jpg"
import metroByT from "../../images/MetroByT-Mobile.png"
import boost from "../../images/boostmobile.png"
import cricket from "../../images/cricket.png"
import homedepot from "../../images/home-depot.png"
import lowes from "../../images/lowes.jpg"
import hdsupply from "../../images/hdsupply.png"
import lcalottery from "../../images/lcalottery.png"
import dewalt from "../../images/dewalt.jpg"
import medicaid from "../../images/medicaid.png"
import tobacco from "../../images/tobacco-free-ca.jpg"
import reilly from "../../images/reilly-auto-parts.png"
import espn from "../../images/espn.png"
import telemundo from "../../images/telemundo.png"
import azteca from "../../images/tv-azteca.jpg"
import western from "../../images/western-union.png"
import money from "../../images/money-gram.png"
import xcel from "../../images/xcel-energy.png"
import cdc from "../../images/CDC-Logo.png"
import california from "../../images/california-state-parks.png"
import metropolitan from "../../images/metropolitan-water-district.jpg"
import endowment from "../../images/the-california-endownment.png"
import volaris from "../../images/volaris.png"
import nongshim from "../../images/nongshim.png"
import bandai from "../../images/bandai-namco.png"
import glidden from "../../images/glidden.jpg"
import tums from "../../images/tums-logo.png"
import tampico from "../../images/tampico.png"

export default class Navigation extends Component {
  render() {
    const params = {
      slidesPerView: 6,
      spaceBetween: 30,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },
      clickable: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    }

    return (
      <Swiper {...params}>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://jobs.molsoncoors.com/MillerCoors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={miller}
                    className="img-fluid grayscale"
                    alt="Miller Coors"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.beamsuntory.com/brands/hornitos"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={beam}
                    className="img-fluid grayscale"
                    alt="Beam Suntory"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://jarritos.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={jarritos}
                    className="img-fluid grayscale"
                    alt="Super Good"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.pepsico.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={pepsico}
                    className="img-fluid grayscale"
                    alt="Pepsico"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.gmodelo.mx/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={modelo}
                    className="img-fluid grayscale"
                    alt="Grupo Modelo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.anheuser-busch.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={busch}
                    className="img-fluid grayscale"
                    alt="Anheuser Busch"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://heinekenmexico.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={moctezuma}
                    className="img-fluid grayscale"
                    alt="Heineken Mexico"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.dickies.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={dickies}
                    className="img-fluid grayscale"
                    alt="Dickies"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.metropcs.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={metroByT}
                    className="img-fluid grayscale"
                    alt="Metro By T Mobile"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.boostmobile.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={boost}
                    className="img-fluid grayscale"
                    alt="Boost Mobile"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.cricketwireless.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={cricket}
                    className="img-fluid grayscale"
                    alt="Cricket Wireless"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="http://www.homedepot.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={homedepot}
                    className="img-fluid grayscale"
                    alt="The Home Depot"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.lowes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={lowes}
                    className="img-fluid grayscale"
                    alt="Lowe's"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="http://www.hdsupply.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={hdsupply}
                    className="img-fluid grayscale"
                    alt="HD Supply"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.calottery.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={lcalottery}
                    className="img-fluid grayscale"
                    alt="California State Lottery"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://dewalt.global/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={dewalt}
                    className="img-fluid grayscale"
                    alt="DeWalt"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://medicaid-help.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={medicaid}
                    className="img-fluid grayscale"
                    alt="Medicaid"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://tobaccofreeca.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={tobacco}
                    className="img-fluid grayscale"
                    alt="Tobacco Free CA"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.oreillyauto.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={reilly}
                    className="img-fluid grayscale"
                    alt="Oreilly Auto Parts"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="http://tv5.espn.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={espn} className="img-fluid grayscale" alt="ESPN" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.telemundo.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={telemundo}
                    className="img-fluid grayscale"
                    alt="Telemundo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.tvazteca.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={azteca}
                    className="img-fluid grayscale"
                    alt="TV Azteca"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.westernunion.com/ph/en/home.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={western}
                    className="img-fluid grayscale"
                    alt="Western Union"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="http://global.moneygram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={money}
                    className="img-fluid grayscale"
                    alt="Money Gram"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.xcelenergy.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={xcel}
                    className="img-fluid grayscale"
                    alt="Xcel Energy"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.cdc.gov/zika/prevention/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={cdc}
                    className="img-fluid grayscale"
                    alt="Centers for Disease Control and Prevention"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="http://www.dbw.parks.ca.gov/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={california}
                    className="img-fluid grayscale"
                    alt="California State Parks"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="http://www.mwdh2o.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={metropolitan}
                    className="img-fluid grayscale"
                    alt="Metropolitan Water District of Southern California"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.calendow.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={endowment}
                    className="img-fluid grayscale"
                    alt="The California Endowment"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.volaris.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={volaris}
                    className="img-fluid grayscale"
                    alt="Volaris"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="http://www.nongshimusa.com/homev2/bowl-noodle/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={nongshim}
                    className="img-fluid grayscale"
                    alt="Nongshim"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.bandainamcoent.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={bandai}
                    className="img-fluid grayscale"
                    alt="Bandai Namco"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.glidden.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={glidden}
                    className="img-fluid grayscale"
                    alt="Glidden"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="https://www.tums.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={tums} className="img-fluid grayscale" alt="Tums" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="swiper-slide">
            <div className="testimonial-content">
              <div className="content">
                <a
                  href="http://www.tampico.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={tampico}
                    className="img-fluid grayscale"
                    alt="Tampico"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Swiper>
    )
  }
}
